.btn-outline-custom {
    /* Change the background color */
    background-color: #ffff;
    /* Change the border color */
    border-color: #850922;
    /* Change text color for better visibility */
    color: #850922;
  }
  /* Optional: Hover and focus effects */
  .btn-outline-custom:hover,
  .btn-outline-custom:focus {
    /* Change background color on hover or focus */
    background-color: #850922;
    /* Change border color on hover or focus */
    border-color: #850922;

    color: #ffff
  }